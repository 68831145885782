// src/pages/Home.tsx
import "./Home.scss";
import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Stack,
  Badge,
} from "react-bootstrap";
import emailIcon from "../images/email-icon.png";
import githubMarkWhite from "../images/github-mark-white.png";
import linkedinMark from "../images/linkedin-mark.png";
const Home: React.FC = () => {
  return (
    <Container className="mt-2">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title as="h4">About Me</Card.Title>
              <Card.Text>
                <div>
                  I am an enthusiastic software developer with extensive
                  knowledge of React, TypeScript, Node, and much more,
                  complemented by a solid foundation of DevOps experience using
                  AWS, Docker, Kubernetes, etc. I have many years of experience
                  in web app focused full stack development, and have taken
                  multiple significant projects from 0 to 1. I have a passion
                  for building excellent products and continuing to learn and
                  improve.
                </div>
                <div className="mt-2">
                  <Button
                    variant="primary"
                    href="mailto:robert@robertlawrenceparker.com"
                  >
                    <Stack direction="horizontal" gap={0}>
                      <div className="button-logo-wrap">
                        <img
                          className="button-logo me-1"
                          src={emailIcon}
                          alt="Email Icon"
                        />
                      </div>
                      <div className="button-text-wrap fw-bold">Email Me</div>
                    </Stack>
                  </Button>
                  <Button
                    className="ms-1"
                    variant="dark"
                    href="https://github.com/bbkpr"
                    target="_blank"
                  >
                    <Stack direction="horizontal" gap={0}>
                      <div className="button-logo-wrap">
                        <img
                          className="button-logo me-1"
                          src={githubMarkWhite}
                          alt="GitHub Logo"
                        />
                      </div>
                      <div className="button-text-wrap fw-bold">GitHub</div>
                    </Stack>
                  </Button>
                  <Button
                    className="ms-1"
                    variant="light"
                    href="https://linkedin.com/in/robertlparker"
                    target="_blank"
                  >
                    <Stack direction="horizontal" gap={0}>
                      <div className="button-logo-wrap">
                        <img
                          className="button-logo me-1"
                          src={linkedinMark}
                          alt="LinkedIn Logo"
                        />
                      </div>
                      <div className="button-text-wrap text-dark fw-bold">
                        LinkedIn
                      </div>
                    </Stack>
                  </Button>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <h2>Resume</h2>
          <Card className="resume-item-card">
            <Card.Body>
              <Card.Title as="h4">
                Staff Software Engineer @ Logicbroker
              </Card.Title>
              <Card.Subtitle as="h6">Feb 2023 - Present</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">React</Badge>
                  <Badge className="ms-1" bg="info">
                    TypeScript
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Node.js
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    AWS
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Postgres
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jest
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jira
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Led initiative to update documentation and effectively
                    communicate information and updates to the platform to the
                    team.
                  </li>
                  <li>
                    Provided expertise in AWS solutions, from recommendation to
                    implementation and maintenance.
                  </li>
                  <li>
                    Drove initiatives to update and refine the codebase and
                    testing procedures, aligning with best industry standards.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="resume-item-card mt-2">
            <Card.Body>
              <Card.Title as="h4">
                Senior Software Engineer @ GlobaliD
              </Card.Title>
              <Card.Subtitle as="h6">Jan 2022 - Nov 2022</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">React</Badge>
                  <Badge className="ms-1" bg="info">
                    TypeScript
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Node.js
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Postgres
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jest
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jira
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Designed and developed reference implementations of GlobaliD
                    Connect product.
                  </li>
                  <li>
                    Re-architected Node.js frameworks into Nest.js to improve
                    maintainability.
                  </li>
                  <li>
                    Built custom Admin Panel with frontend/backend capabilities,
                    connecting multiple data sources for streamlined service
                    operations.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="resume-item-card mt-2">
            <Card.Body>
              <Card.Title as="h4">Software Architect @ Broadvoice</Card.Title>
              <Card.Subtitle as="h6">Aug 2016 - Dec 2021</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">React</Badge>
                  <Badge className="ms-1" bg="info">
                    TypeScript
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Node.js
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    C#
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Elasticsearch
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    MySQL
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jest
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Jira
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Planned and implemented architectural decisions, technology
                    stack selection, and overall direction of development
                    efforts.
                  </li>
                  <li>
                    Introduced the team to React, Redux, MobX, TypeScript,
                    Webpack and Node.js, in order to progressively upgrade
                    legacy front-end systems to modern standards, and
                    efficiently develop new applications.
                  </li>
                  <li>
                    Mentored junior developers and helped provide them with the
                    knowledge and resources they needed to effectively
                    contribute to the team's goals.
                  </li>
                  <li>
                    Integrated with back-end systems developed in parallel with
                    the front-end. Worked on back-end systems as needed, and
                    communicated with both teams to ensure overall consistency
                    and compatibility.
                  </li>
                  <li>
                    Communicated directly with stakeholders to set expectations
                    and determine the best way to deliver results that satisfy
                    business requirements in a cost- and time-effective manner.
                  </li>
                  <li>
                    Mitigated the risk of project derailment by anticipating and
                    preempting problems that could cause delays and technical
                    debt.
                  </li>
                  <li>
                    Produced documentation, tools, and materials to help the
                    team work as effectively as possible.
                  </li>
                  <li>
                    Worked with a mid-size team to update and streamline various
                    mission-critical legacy systems inherited via corporate
                    merger into an extensible micro-service architecture.
                  </li>
                  <li>
                    Reverse-engineered legacy database schemata and business
                    logic, and architected solutions to incrementally improve
                    data structure, performance, and maintainability.
                  </li>
                  <li>
                    Introduced more formal software interface planning into the
                    team's project workflow, including UML/ERD software,
                    allowing the team to visualize, understand, and collaborate
                    more effectively while planning each project phase.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="resume-item-card mt-2">
            <Card.Body>
              <Card.Title as="h4">
                Senior Lead Programmer @ Flavorus/See Tickets
              </Card.Title>
              <Card.Subtitle as="h6">Oct 2015 - Jul 2016</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">React</Badge>
                  <Badge className="ms-1" bg="info">
                    TypeScript
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Node.js
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    C#
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Elasticsearch
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    MySQL
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    MS SQL Server
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Teamcity
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Zendesk
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Led a team to maintain and optimize codebase for high
                    traffic, public E-Commerce website.
                  </li>
                  <li>
                    Architected progressive backward compatible solutions to
                    maximize refactoring efforts from legacy system.
                  </li>
                  <li>
                    Established automated CI/CD infrastructure using TeamCity
                    and Octopus Deploy.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="resume-item-card mt-2">
            <Card.Body>
              <Card.Title as="h4">
                Senior Creative Technologist @ Mullen Lowe U.S.
              </Card.Title>
              <Card.Subtitle as="h6">Aug 2013 - Oct 2015</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">React</Badge>
                  <Badge className="ms-1" bg="info">
                    TypeScript
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    AngularJS
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    C#
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Elasticsearch
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    MySQL
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Teamcity
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Developed an enterprise DAM system based on ServiceStack,
                    Elasticsearch, Redis and AngularJS.
                  </li>
                  <li>
                    Established CI/CD mechanisms with TeamCity and Octopus
                    Deploy to facilitate stable deployments.
                  </li>
                  <li>
                    Optimized documentation, testing and deployment procedures
                    for the entire organization.
                  </li>
                  <li>
                    Led engineering efforts behind{" "}
                    <a
                      href="https://youtu.be/_PJvULfDtL4"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Acura Prism system at Sundance 2014
                    </a>{" "}
                    using Elasticsearch, ServiceStack, and Neo4j.
                  </li>
                  <li>
                    Revamped the Acura dealer network's advertising distribution
                    and content management system from scratch using
                    ServiceStack and AngularJS, while preserving compatibility
                    with the legacy system.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="resume-item-card mt-2">
            <Card.Body>
              <Card.Title as="h4">Developer @ ScoreBig Inc</Card.Title>
              <Card.Subtitle as="h6">Oct 2012 - Apr 2013</Card.Subtitle>
              <Card.Text className="resume-item-card-text">
                <p>
                  <Badge bg="info">JavaScript</Badge>
                  <Badge className="ms-1" bg="info">
                    C#
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    Elasticsearch
                  </Badge>
                  <Badge className="ms-1" bg="info">
                    MySQL
                  </Badge>
                </p>
                <ul className="resume-entry-list">
                  <li>
                    Developed and updated both back-end and front-end systems
                    for a consumer facing event ticketing website.
                  </li>
                  <li>
                    Developed comprehensive spec-based unit tests for core
                    systems using MSpec, designed and developed UI regression
                    tests using Selenium and NUnit.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
